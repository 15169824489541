<script>
import apiClient from '@/services/axios'
import { Pie } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const pieOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: 'left',
    labels: {
      fontColor: '#000',
      fontSize: 15,
    },
  },
  tooltips: {
    titleFontSize: 16,
    bodyFontSize: 14,
  },
  title: {
    display: true,
    position: 'top',
    text: ['Expense', 'Chart of Account(COA)'],
    fontSize: 14,
  },
  plugins: {
    datalabels: {
      display: true,
      color: '#000',
      font: {
        size: 15,
      },
      formatter: (value, ctx) => {
        const sum = ctx.chart.data.datasets[0].data.reduce((a, b) => a + b, 0)
        const percentage = (value * 100) / sum
        return Math.round(percentage) + '%'
      },
    },
  },
}
export default {
  extends: Pie,
  data() {
    return {
      pieOptions,
      totalExpense: 0,
    }
  },
  mounted () {
    this.addPlugin(ChartDataLabels)
    this.getPieChartData()
  },
  methods: {
    getPieChartData () {
      apiClient.get('api/pie/chart')
        .then(response => {
          this.totalExpense = response.data.totalExpense
          this.renderChart({
            labels: response.data.labels,
            datasets: [{
              data: response.data.data,
              backgroundColor: response.data.backgroundColor,
              borderColor: response.data.backgroundColor,
              borderWidth: 1,
            }],
          }, this.pieOptions)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
