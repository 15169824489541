<script>
import apiClient from '@/services/axios'
import { Bar } from 'vue-chartjs'

const barOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
    labels: {
      fontColor: '#000',
      fontSize: 15,
      backgroundColor: 'red',
    },
  },
  tooltips: {
    titleFontSize: 16,
    bodyFontSize: 14,
  },
  title: {
    display: true,
    position: 'top',
    text: ['Expense', 'Chart of Account(COA)'],
    fontSize: 14,
  },
}
export default {
  name: 'barChart',
  extends: Bar,
  data() {
    return {
      barOptions,
    }
  },
  mounted () {
    this.getBarChartData()
  },
  methods: {
    getBarChartData () {
      apiClient.get('api/bar/chart')
        .then(response => {
          const monthNameArray = response.data.month_name_array
          const monthExpenseArray = response.data.month_expense_array
          const backgroundColor = response.data.backgroundColor
          const year = response.data.year
          this.renderChart({
            labels: monthNameArray,
            datasets: [{
              label: 'Monthly Expense in ' + year,
              data: monthExpenseArray,
              backgroundColor: backgroundColor,
              borderColor: backgroundColor,
              borderWidth: 1,
            }],
          }, this.barOptions)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
